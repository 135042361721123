/**
 * Expiration time unit
 * s: seconds
Â * m: minute
Â * h: hour
Â * d: day
Â * w: week
Â * y: year
Â * t: Custom (millisecond)
 */
export type ExpiredUnit = 's' | 'm' | 'h' | 'd' | 'w' | 'y' | 't';

/**
 * å¤æ­æ¯å¦å¨æµè§å¨ä¸­æ¸²æAngularåºç¨
 * `decorator` æ æ³ç´æ¥ä½¿ç¨Angular `PLATFORM_ID` æ è¯
 * è¿å¸¦æ¥çå¥½å¤æ¯å½æå¡ç«¯èªè¡å¡«å Document æ¶ä¹è½å¾å¥½çè¯å«
 */
export const isBrowser = typeof document === 'object' && !!document;

export class StorageUtil {
  static get(storage: Storage, key: string): any {
    if (storage == null) {
      return null;
    }
    const value = StorageUtil.parse(storage.getItem(key) || 'null') || null;
    if (value === null) return null;
    if (
      typeof value === 'object' &&
      typeof value._expired !== 'undefined' &&
      value._expired !== 0 &&
      +new Date() > value._expired
    ) {
      StorageUtil.remove(storage, key);
      return null;
    }

    return value._value || null;
  }

  static set(
    storage: Storage,
    key: string,
    value: any,
    expiredAt: number = 0,
    expiredUnit: ExpiredUnit = 't',
  ): void {
    if (storage == null) {
      return ;
    }
    storage.setItem(
      key,
      StorageUtil.stringify({
        _expired: StorageUtil.getExpired(expiredAt, expiredUnit),
        _value: value,
      }),
    );
  }

  static remove(storage: Storage, key: string): void {
    if (storage == null) {
      return ;
    }
    storage.removeItem(key);
  }

  static key(storage: Storage, index: number): string {
    return storage == null ? '' : storage.key(index);
  }

  private static getExpired(val: number, unit: ExpiredUnit): number {
    if (val <= 0) return 0;
    const now = +new Date();
    switch (unit) {
      case 's': // ç§
        return now + 1000 * val;
      case 'm': // å
        return now + 1000 * 60 * val;
      case 'h': // æ¶
        return now + 1000 * 60 * 60 * val;
      case 'd': // å¤©
        return now + 1000 * 60 * 60 * 24 * val;
      case 'w': // å¨
        return now + 1000 * 60 * 60 * 24 * 7 * val;
      case 'y': // å¹´
        return now + 1000 * 60 * 60 * 24 * 365 * val;
      case 't': // èªå®ä¹
        return now + val;
    }
    return 0;
  }

  private static stringify(value: any) {
    return JSON.stringify(value);
  }

  private static parse(text: string) {
    try {
      return JSON.parse(text) || null;
    } catch (e) {
      return text;
    }
  }
}
